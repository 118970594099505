import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import TextHeader from "../atoms/textHeader"
import HashLink from "../atoms/anchorLink"
import IconPhone from "../../assets/images/Icons/icon-phone.svg"

const DropDown = keyframes`
    0%{
        transform: translateY(-20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
`

const JumboHeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 80vh;
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 575px) {
    min-height: 575px;
  }
`

const JumboHeaderContent = styled.div`
  margin-left: 15%;
  @media (max-width: 991px) {
    margin-top: 10%;
    margin-left: 0;
    width: 68%;
  }
  @media (max-width: 575px) {
    margin-top: 80px;
    width: 90%;
  }
`

const Button = styled(HashLink)`
  background-color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
  color: white;
  padding: 20px 60px;
  border-radius: 90px;
  border-color: black;
  display: inline-block;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
  @media (max-width: 770px) {
    display: block;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 575px) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
  }
`

const ButtonWhite = styled(Button)`
  background-color: white;
  color: black;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
  opacity: 0;
  animation: ${DropDown} 0.7s 0.8s ease forwards;
  @media (max-width: 575px) {
    width: 90%;
    margin: auto;
  }
`

const JumboTextHeader = styled(TextHeader)`
  font-size: 1.1rem;
  font-weight: 100;
  color: white;
  opacity: 0;
  animation: ${DropDown} 0.5s 0.4s ease forwards;
`
const JumboTextHeaderBig = styled(JumboTextHeader)`
  font-size: 2.3rem;
  font-weight: bold;
  animation-delay: 0.6s;
  @media (max-width: 991px) {
    width: 100%;
  }
`

const JumboTextHeaderBigMain = styled(JumboTextHeaderBig)`
  width: 100%;
`

const JumboContentMain = () => {
  const data = useStaticQuery(query)

  return (
    <JumboHeaderBox>
      <JumboHeaderContent>
        <JumboTextHeader>{data.allDatoCmsTitle.edges[0].node.podtytul}</JumboTextHeader>
        <JumboTextHeaderBigMain
          dangerouslySetInnerHTML={{ __html: data.allDatoCmsTitle.edges[0].node.tytul }}
        ></JumboTextHeaderBigMain>
        <ButtonContainer>
          <Button to="/#others" textColor="white">
            <span style={{ color: "white" }}>Zobacz Ofertę</span>
          </Button>
          <ButtonWhite to="/#contact">
            <img style={{ width: `25px`, marginBottom: `-7px`, marginRight: `3px` }} src={IconPhone} alt="Phone icon" />{" "}
            Skontaktuj się
          </ButtonWhite>
        </ButtonContainer>
      </JumboHeaderContent>
    </JumboHeaderBox>
  )
}

const query = graphql`
  query Title {
    allDatoCmsTitle {
      edges {
        node {
          podtytul
          tytul
        }
      }
    }
  }
`

export default JumboContentMain
