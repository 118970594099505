import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Slide from "../atoms/slide"
import DotsContainer from "../molecules/dotsContainer"
import Dot from "../atoms/dot"
import { GatsbyImage } from "gatsby-plugin-image"

const SliderBox = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  overflow-x: hidden;
`

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`

const BackgroundSliderMain = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [intervalval, setIntervalval] = useState()
  const [isClicked, setClick] = useState(false)
  const data = useStaticQuery(query)
  let time = data.allDatoCmsSlider.edges[0].node.czas
  useEffect(() => {
    let start = setInterval(() => {
      setCurrentSlide((prevstate) => prevstate + 1)
    }, time)
    setIntervalval(start)
    return () => clearInterval(start)
  }, [])

  const handleStart = () => {
    let start = setInterval(() => {
      setCurrentSlide((prevstate) => prevstate + 1)
    }, time)
    setIntervalval(start)
  }

  const handleReset = () => {
    clearInterval(intervalval)
    if (isClicked === false) {
      setTimeout(() => {
        handleStart()
        setClick(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (currentSlide === data.allDatoCmsSlider.edges[0].node.slideimg.length) {
      setCurrentSlide(0)
    }
  }, [currentSlide])

  const handleTouch = (e) => {
    const direction = e.changedTouches[0]
    let windowSize
    if (window.innerWidth <= 736) {
      windowSize = window.innerWidth / 4
    } else {
      windowSize = window.innerWidth / 2
    }
    if (direction.clientX / 2 <= windowSize) {
      if (currentSlide === 0) {
        return setCurrentSlide(data.allFile.edges.length - 1)
      } else {
        handleReset()
        setClick(true)
        return setCurrentSlide((prevstate) => prevstate - 1)
      }
    } else if (direction.clientX / 2 >= windowSize) {
      if (currentSlide + 1 === data.allFile.edges.length) {
        handleReset()
        setClick(true)
        return setCurrentSlide(0)
      } else {
        handleReset()
        setClick(true)
        return setCurrentSlide((prevstate) => prevstate + 1)
      }
    }
  }

  return (
    <SliderBox>
      {data.allDatoCmsSlider.edges[0].node.slideimg.map((slide, index) => (
        <Slide
          activeSlideIndex={currentSlide}
          slideIndex={index}
          key={index}
          active={currentSlide === index ? true : false}
          onTouchStart={(e) => handleTouch(e)}
        >
          <StyledImg key={index + 5} image={slide.gatsbyImageData} />
        </Slide>
      ))}
      <DotsContainer>
        {data.allDatoCmsSlider.edges[0].node.slideimg.map((edge, index) => (
          <Dot
            active={currentSlide === index ? true : false}
            key={index}
            onClick={() => {
              setCurrentSlide(index), handleReset(index), setClick(true)
            }}
          >
            {index}
          </Dot>
        ))}
      </DotsContainer>
    </SliderBox>
  )
}

const query = graphql`
  query AllSlidesImages {
    allDatoCmsSlider {
      edges {
        node {
          slideimg {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          czas
        }
      }
    }
  }
`

export default BackgroundSliderMain
