import React from "react"
import styled from "styled-components"
import Contact from "../molecules/contactBox"
import BackgroundSliderMain from "./backgroundSliderMain"
import JumboContentMain from "../molecules/jumboContentMain"

const JumboBox = styled.div`
  position: relative;
  width: 100vw;
  height: 90vh;
  min-height: 575px;
`

const JumboMain = () => {
  return (
    <JumboBox>
      <BackgroundSliderMain />
      <JumboContentMain />
      <Contact />
    </JumboBox>
  )
}

export default JumboMain
